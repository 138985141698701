import { css } from '@gf/cross-platform-lib/styled-components';

export const styles = {
  wrapper: css<{ schoolColor: string; padding: number }>`
    background-color: ${props => props.schoolColor};
    padding: ${({ padding }) => padding}px;
    border-radius: 500px;

    display: flex;
    align-items: center;
    justify-content: center;
  `
};
